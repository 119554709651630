import Utils from "./Utils";
import i18next from "i18next";

export default class Config {
	
	static buildDatetime = '2024-04-12 01:50'
	static buildVersion = '1.5.42'
	
	static INSTITUTE_ALIAS_KFU = 'kfu';
	static INSTITUTE_ALIAS_ANKARA = 'ankara';
	
	static instituteAlias = Config.INSTITUTE_ALIAS_KFU;
	// static instituteAlias = Config.INSTITUTE_ALIAS_ANKARA;
	
	static version = '0.1';
	static version_datetime = '2022-06-17 15:16';
	
	static kfuUrl = 'https://kpfu.ru/';
	static ankaraUrl = 'https://www.ankara.edu.tr/';
	
	static emptyValuePlug = '-';
	
	static projectName = 'StudeRus';
	
	static prodFrontendHost = 'studerus.ru';
	
	static prodBackendUrl = 'https://web.studerus.ru';
	static devBackendUrl = 'https://web-test-i3rh8ea17p.studerus.ru';
	// static devBackendUrl = 'https://yii2-oleg-hoas7q4ch6.studerus.ru';
	
	static devHosts = [
		'localhost',
	]
	
	static testHosts = [
		'test.studerus.ru',
	]
	
	static prodHosts = [
		'studerus.ru',
		'www.studerus.ru',
		'www-new.studerus.ru',
		'build.studerus.ru',
	]
	
	static backendUrlMap = {
		
		'localhost': Config.devBackendUrl,
		
		'test.studerus.ru': Config.devBackendUrl,
		
		'studerus.ru': Config.prodBackendUrl,
		'www.studerus.ru': Config.prodBackendUrl,
		'www-new.studerus.ru': Config.prodBackendUrl,
		
	}
	
	static backendUrl = Utils.isProd()
		? Config.prodBackendUrl
		: Config.devBackendUrl;
	
	static trainersAppStoreUrl = 'https://apps.apple.com/ru/app/studerus-game/id1594352206?l=en';
	
	// static defaultLangAlias = 'ru-RU';
	static defaultLangAlias = 'en-US';
	
	static maxQuestionsPageSize = 5;
	
	static tinyMceMainConfig = {
		// language: i18next.language == 'ru-RU' ? 'ru' : 'en',
		language: 'ru',
		height: 500,
		statusbar: true,
		menubar: true,
		plugins: [
			'advlist',
			'autolink',
			'lists',
			'link',
			'image',
			'charmap',
			'print',
			'preview',
			'anchor',
			'searchreplace',
			'visualblocks',
			'code',
			'fullscreen',
			'insertdatetime',
			'media',
			'table',
			'paste',
			'table',
			'charmap',
			'code',
			// 'codesample',
			'fullscreen',
			// 'formula',
			// 'formula2',
		],
		toolbar1: [
			'undo',
			'redo',
			'styles',
			'fontsize',
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'forecolor',
			'backcolor',
			'lineheight',
			'bullist',
			'numlist',
			'align',
			'outdent',
			'indent',
			'removeformat',
			'table',
			'image',
			'media',
			'charmap',
			// 'paste',
			'code',
			'fullscreen',
			'codesample',
		].join(' '),
	};
	
	static tinyMceModalConfig = {
		// language: i18next.language == 'ru-RU' ? 'ru' : 'en',
		language: 'ru',
		height: 500,
		menubar: true,
		statusbar: true,
		plugins: [
			'advlist',
			'autolink',
			'lists',
			'link',
			'image',
			'charmap',
			'print',
			'preview',
			'anchor',
			'searchreplace',
			'visualblocks',
			'code',
			'insertdatetime',
			'media',
			'table',
			'paste',
			'table',
			'charmap',
			'code',
			'codesample',
			'fullscreen',
			// 'formula',
		],
		toolbar: [
			'undo',
			'redo',
			'styles',
			'fontsize',
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'forecolor',
			'backcolor',
			'lineheight',
			'bullist',
			'numlist',
			'align',
			'outdent',
			'indent',
			'removeformat',
			'table',
			// 'image',
			// 'media',
			// 'charmap',
			'code',
			'fullscreen',
			// 'codesample',
			// 'paste',
			// 'formula',
		].join(' '),
	};
	
	static asciiMathSyntaxUrl = 'http://asciimath.org/#syntax';
	
	static allowFormFake() {
		return Utils.isLoc();
		// return Utils.isDev;
		// return Utils.isDev && Utils.isLoc();
		// return false;
		// return true;
	}
	
	static ckeConfig = {
		
		removePlugins: ['Markdown'],
		
		toolbar: [
			'undo', 'redo',
			'|', 'heading', 'bold', 'italic', 'underline', 'strikethrough',
			'|', 'link', 'numberedList', 'bulletedList', 'insertTable', 'tableRow', 'tableColumn',
			'|', 'alignment',
		],
		
		// todo WTF doesn't work ?!
		table: {
			tableToolbar: [
				'tableColumn',
				'tableRow',
				'mergeTableCells',
			],
		},
		
	};
	
	static acceptedFiles = [
		
		{
			name: 'PDF',
			mime: 'application/pdf',
			ext: 'pdf'
		},
		
		{
			name: 'Microsoft Excel',
			mime: 'application/vnd.ms-excel',
			ext: 'xls'
		},
		
		{
			name: 'Microsoft Excel',
			mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			ext: 'xlsx'
		},
		
		{
			name: 'Microsoft Word',
			mime: 'application/msword',
			ext: 'doc'
		},
		
		{
			name: 'Microsoft Word',
			mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			ext: 'docx'
		},
		
		{
			name: 'Microsoft PowerPoint',
			mime: 'application/vnd.ms-powerpoint',
			ext: 'ppt'
		},
		
		{
			name: 'Microsoft PowerPoint',
			mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
			ext: 'pptx'
		},
		
		{
			name: 'OpenDocument',
			mime: 'application/vnd.oasis.opendocument.text',
			ext: 'odt'
		},
		
		// {
		//     name: 'Zip',
		//     mime: 'application/zip',
		//     ext: 'zip'
		// },
		
		// {
		//     name: '7z',
		//     mime: 'application/x-7z-compressed',
		//     ext: '7z'
		// },
	
	];
	
	static getAcceptedFilesMimes = () => {
		return this.acceptedFiles.map(data => data.mime).join(', ');
	};
	
	static getAcceptedFilesSummaryArray = () => {
		return this.acceptedFiles.map(data => {
			return data.name + ' (' + data.ext + ')';
		});
	};
	
	static permissions = {
		
		'__tpl': [
			// 'owner',
			// 'admin',
			// 'teacher',
			// 'student',
		],
		
		'seeQuestionsPoints': [
			'owner',
			'admin',
			'teacher',
			// 'student',
		],
		
		'debugInfo': [
			// 'owner',
			// 'admin',
			// 'teacher',
			// 'student',
		],
		
		'smallDebugInfo': [
			'owner',
			'admin',
			'teacher',
			// 'student',
		],
		
		'dev': [
			'owner',
			// 'admin',
			// 'teacher',
			// 'student',
		],
		
		'editSelfProfile': [
			'owner',
			'admin',
			'teacher',
			'student',
		],
		
		'manageStudents': [
			'owner',
			'admin',
		],
		
		'manageGroups': [
			'owner',
			'admin',
		],
		
		'groupsBottomControls': [
			'owner',
		],
		
		'delGroups': [],
		
		'questionDebugInfo': [
			'owner',
			'admin',
			// 'teacher',
		],
		
		'contentId': [
			'owner',
			'admin',
			// 'teacher',
		],
		
		'manageCourses': [
			'owner',
			'admin',
			// 'teacher',
		],
		
		'manageLessons': [
			'owner',
			'admin',
			// 'teacher',
		],
		
		'manageThemes': [
			'owner',
			'admin',
			// 'teacher',
		],
		
		'manageMaterials': [
			'owner',
			'admin',
			// 'teacher',
		],
		
		'manageQuestions': [
			'owner',
			'admin',
			// 'teacher',
		],
		
		'manageHomework': [
			'owner',
			'admin',
			'teacher',
		],
		
		'manageSched': [
			'owner',
			'admin',
			// 'teacher',
		],
		
		'showRemovedContent': [
			'owner',
			'admin',
			// 'teacher',
		],
		
		'manageUsers': [
			'owner',
			'admin',
			// 'teacher',
			// 'student',
		],
		
		'manageAdmins': [
			'owner',
			// 'admin',
			// 'teacher',
			// 'student',
		],
		
		'manageTeachersOnly': [
			// 'owner',
			// 'admin',
			// 'teacher',
			// 'student',
		],
		
		'manageDepartments': [
			'owner',
			'admin',
			// 'teacher',
			// 'student',
		],
		
		'manageTopLevelDepartments': [
			'owner',
			// 'admin',
			// 'teacher',
			// 'student',
		],
		
		'manageTrainers': [
			'owner',
			// 'admin',
			// 'teacher',
			// 'student',
		],
		
		'manageTrainerLevels': [
			'owner',
			// 'admin',
			// 'teacher',
			// 'student',
		],
		
		'clearTrainersLevels': [
			// 'owner',
			// 'admin',
			// 'teacher',
			'student',
		],
		
		'applyAnswers': [
			// 'owner',
			// 'admin',
			// 'teacher',
			'student',
		],
		
		'removeContent': [
			'owner',
			// 'admin',
			// 'teacher',
			// 'student',
		],
		
		'skinTestPage': [
			'owner',
			// 'admin',
			// 'teacher',
			// 'student',
		],
		
		'moveCourses': [
			// 'owner',
			// 'admin',
			// 'teacher',
			// 'student',
		],
		
		'copyMaterials': [
			'owner',
			'admin',
			// 'teacher',
			// 'student',
		],
		
		'commentQuestions': [
			// 'owner',
			// 'admin',
			// 'teacher',
			'student',
		],
		
		'answerQuestions': [
			// 'owner',
			// 'admin',
			// 'teacher',
			'student',
		],
		
		'seeRightAnswer': [
			'owner',
			'admin',
			'teacher',
			// 'student',
		],
		
		'seeMaterialsDrafts': [
			'owner',
			'admin',
			'teacher',
			// 'student',
		],
		
		'manageMaterialsAccess': [
			'owner',
			'admin',
			'teacher',
			// 'student',
		],
		
		'showArchivedCourses': [
			'owner',
			// 'admin',
			// 'teacher',
			// 'student',
		],
		
		'printMaterials': [
			'owner',
			'admin',
			'teacher',
			// 'student',
		],
		
		'useBreakpointIndicator': [
			'owner',
			// 'admin',
			// 'teacher',
			// 'student',
		],
		
		'disableUnwantedUseAnswerTemplates': [
			'owner',
			'admin',
			// 'teacher',
			// 'student',
		],
		
	};
	
	static studentRoleId = 5;
	static teacherRoleId = 6;
	static adminRoleId = 2;
	static ownerRoleId = 1;
	
	static coursesCurrentDepartmentIdKey = 'courses_current_department_id';
	static coursesCurrentCourseIdKey = 'courses_current_course_id';
	static coursesCurrentLessonIdKey = 'courses_current_lesson_id';
	static coursesCurrentThemeIdKey = 'courses_current_theme_id';
	static coursesCurrentThemeIndexKey = 'courses_current_theme_index';
	static coursesCurrentMaterialIdKey = 'courses_current_material_id';
	static coursesCurrentMaterialIndexKey = 'courses_current_material_index';
	
	static storeModeSave = 'save';
	static storeModeDel = 'del';
	
	static grades = [
		{
			percent: 0,
			// name: i18next.t(""),
		},
		{
			percent: 25,
			// name: i18next.t(""),
		},
		{
			percent: 50,
			// name: i18next.t(""),
		},
		{
			percent: 75,
			// name: i18next.t(""),
		},
		{
			percent: 100,
			// name: i18next.t(""),
		},
	];
	
	static setMaterialId = (themeId, materialId) => {
		let key = 'theme-' + themeId + '_material-id';
		localStorage.setItem(key, materialId);
	};
	
	static getMaterialId = (themeId) => {
		let key = 'theme-' + themeId + '_material-id';
		return parseInt(localStorage.getItem(key));
	};
	
	static setMaterialIndex = (themeId, materialIndex) => {
		let key = 'theme-' + themeId + '_material-index';
		localStorage.setItem(key, materialIndex);
	};
	
	static getMaterialIndex = (themeId) => {
		let key = 'theme-' + themeId + '_material-index';
		return parseInt(localStorage.getItem(key));
	};
	
	static setQuestionId = (materialId, questionId) => {
		let key = 'material-' + materialId + '_question-id';
		localStorage.setItem(key, questionId);
	};
	
	static getQuestionId = (materialId) => {
		let key = 'material-' + materialId + '_question-id';
		return localStorage.setItem(key);
	};
	
	static setQuestionIndex = (materialId, questionIndex) => {
		let key = 'material-' + materialId + '_question-index';
		localStorage.setItem(key, questionIndex);
	};
	
	static getQuestionIndex = (materialId) => {
		let key = 'material-' + materialId + '_question-index';
		return parseInt(localStorage.getItem(key));
	};
	
}