import React from 'react';
import PropTypes from 'prop-types';
import Material from "../../models/Material";
import MaterialView from "./MaterialView";
import User from "../../models/User";
import Utils from "../../helpers/Utils";
import axios from "axios";
import {withRouter} from "react-router";
import Logger from "../../helpers/Logger";
import i18next from "i18next";

class MaterialPage extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			material: null,
		};
	}
	
	getMaterialId = () => {
		return this.props.match.params.materialId;
	}
	
	loadMaterial = () => {
		
		const logName = 'MaterialPage.loadMaterial';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props.preloader, 'this.props.preloader', logAllow);
		
		let id = this.getMaterialId();
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		axios({
			method: 'get',
			url: Utils.apiUrl('materials') + '/' + id,
			data: {},
			params: {
				accessToken: Utils.getUserToken(),
				// id: id,
			},
		}).then((response) => {
			
			const logName = 'MaterialPage.loadMaterial.ajax.done';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Logger.log(response, 'response', logAllow);
			
			Logger.log(response.data, 'response.data', logAllow);
			
			if (response.data) {
				
				let material = new Material(response.data);
				
				this.setState((prevState) => {
					return {
						material: material,
					}
				});
				
			}
			
			Logger.groupEnd(logAllow);
			
		}).catch((error) => {
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			console.log('error.response = %o', error.response);
			
			if (error.response && error.response.data) {
				window.alert(error.response.data.message);
			} else {
				window.alert(error.message);
			}
			
		});
		
		Logger.groupEnd(logAllow);
		
	}
	
	componentDidMount() {
		this.loadMaterial();
	}
	
	render() {
		
		const logName = 'MaterialPage.render';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props, 'this.props', logAllow);
		
		let material = this.state.material;
		Logger.log(material, 'material', logAllow);
		
		let materialId = this.getMaterialId();
		Logger.log(materialId, 'materialId', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div className={'MaterialPage'}>
				
				<h1>{i18next.t("Material")} #{materialId} | {material ? material.name : '...'}</h1>
				
				{(material) && (
					<MaterialView
						material={material}
						user={this.props.user}
						preloader={this.props.preloader}
					/>
				)}
				
			</div>
			
		);
		
	}
	
}

MaterialPage.propTypes = {
	
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	
	// material: PropTypes.instanceOf(Material).isRequired,
	// materialId: PropTypes.number.isRequired,
	
	user: PropTypes.instanceOf(User).isRequired,
	
	preloader: PropTypes.object,
	
};

MaterialPage.defaultProps = {};

export default withRouter(MaterialPage);