import Material from "./Material";

export default class Theme {
	
	constructor(data = {}) {
		this.id = data.id;
		this.name = data.name;
		this.about = data.about;
		this.lesson_id = data.lesson_id;
		this.is_active = data.is_active;
		this.sort = data.sort;
		this.progress = data.progress || 0;
		this.copy_of_id = data.copy_of_id;
		this.materials = data.materials ? data.materials.map(x => new Material(x)) : [];
		this.questions_count = data.questions_count
	}
	
	getName = () => {
		return this.name ? this.name : 'Тема №' + this.id;
	};
	
}