import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	
	'ru-RU': {
		translation: {
			"About": "Описание",
			"Accepted file types": "Допустимые типы файлов",
			"Accordance": "Соответствие",
			"Accordances": "Соответствия",
			"Account": "Личный кабинет",
			"Add": "Добавить",
			"Add course": "Добавить курс",
			"Add department": "Добавить продразделение",
			"Add group": "Добавить группу",
			"Add lesson": "Добавить урок",
			"Add student": "Добавить студента",
			"Add teacher": "Добавить преподавателя",
			"Add theme": "Добавить тему",
			"Add trainer": "Добавить тренажёр",
			"Add user": "Добавить пользователя",
			"Additional text": "Дополнительный текст",
			"Admin": "Админ",
			"Admin++": "Админ++",
			"All groups": "Все группы",
			"Answer is empty": "Ответ не указан",
			"Answer option": "Вариант ответа",
			"Answer options": "Варианты ответов",
			"Any numner": "Произвольное число",
			"Are you shure to submit this answer?": "Сохранить ответ?",
			"Are you shure?": "Вы уверены?",
			"Calendar": "Календарь",
			"Cancel": "Отмена",
			"Check": "Проверить",
			"Clear": "Сброс",
			"Completed": "Прохождение завершено",
			"Content to sort": "Контент для сортировки",
			"Content to sort item": "Элемент контента для сортировки",
			"Copy invite text": "Скопировать приглашение",
			"Count of the underscore symbols regulates the width of the input field": "Количество подчёркиваний регулирует ширину поля ввода",
			"Course": "Курс",
			"Courses": "Курсы",
			"Courses not found": "Курсы не найдены",
			"Date": "Дата",
			"Department": "Подразделение",
			"Departments": "Подразделения",
			"Departments not found": "Подразделения не найдены",
			"Duration (minutes)": "Время на прохождение (в минутах)",
			"Edit": "Изменить",
			"Edit user #{{id}}": "Изменить пользователя №{{id}}",
			"End": "Завершить",
			"Files": "Файлы",
			"Formula": "Формула",
			"Formula editor with preview": "Редактор формул с предпросмотром",
			"Free typing": "Свободный ввод",
			"Group": "Группа",
			"Group name or it's part": "Название группы или его часть",
			"Groups": "Группы",
			"Groups not found": "Группы не найдены",
			"Hide": "Скрыть",
			"Homework": "Домашняя работа",
			"If not defined, time is unlimited": "Если не указано, то время не ограничено",
			"Integer number": "Целое число",
			"Invalid login or password": "Неверный логин или пароль",
			"Kazan Federal University": "Казанский Федеральный Университет",
			"Lesson": "Урок",
			"Lessons": "Уроки",
			"Lessons and themes": "Уроки и темы",
			"Lessons not found": "Уроки не найдены",
			"Link": "Ссылка",
			"Logout": "Выйти",
			"Main text": "Основной текст",
			"Materials": "Материалы",
			"Move down": "Ниже",
			"Move to archive": "Поместить в архив",
			"Move up": "Выше",
			"Multistring test": "Многострочный текст",
			"New course": "Новый курс",
			"New lesson": "Новый урок",
			"New material": "Новый материал",
			"New theme": "Новая тема",
			"No files selected": "Файлы не выбраны",
			"no name": "без названия",
			"Notifications not found": "Уведомления не найдены",
			"No schedule for selected date": "Нет расписания для выбранной даты",
			"No tasks available": "Задания не найдены",
			"Not all clouds are used": "Не все облака использованы",
			"Not all fields are filled": "Не все поля заполнены",
			"Not all fields are filled correctly": "Не все поля заполнены верно",
			"Not answer options selected": "Не выбраны варианты ответа",
			"Notifications": "Уведомления",
			"Open": "Открыть",
			"Original text": "Исходный текст",
			"Overall progress": "Общий прогресс",
			"Place": "Место",
			"Preview documents": "Документы для предпросмотра",
			"Preview images": "Изображения для предпросмотра",
			"Proceed": "Продолжить",
			"Progress": "Прогресс",
			"Promo video link": "Ссылка на промо-видео",
			"Published": "Опубликовано",
			"Reset": "Сбросить",
			"Result text": "Результирующий текст",
			"Return from archive": "Вернуть из архива",
			"Right": "Верно",
			"Right answer": "Правильный ответ",
			"Role": "Роль",
			"Save": "Сохранить",
			"Save answer": "Ответить",
			"Schedule": "Расписание",
			"Select": "Выбрать",
			"Select course": "Выберите курс",
			"Select from options": "Выбор из вариантов",
			"Select from the list": "Выберите из списка",
			"Select lesson": "Выберите урок",
			"Send E-mail": "Отправить E-mail",
			"Show archived": "Показать архивные",
			"Shuffle answers options": "Перемешивать варианты ответов",
			"Skins": "Темы оформления",
			"Sorry, you can't edit this item": "Извините, вы не можете редактировать данный объект",
			"Sorting": "Сортировка",
			"String": "Строка",
			"Student": "Студент",
			"Student's answer": "Ответ студента",
			"Students": "Студенты",
			"Style of background": "Стиль фона",
			"Style of borders": "Стиль рамки",
			"Substitution (\"clouds\")": "Подстановка (выбор из \"облака\")",
			"Substitution (select)": "Подстановка (выбор из списка)",
			"Substitution (table)": "Подстановка (таблица)",
			"Substitution (typing)": "Подстановка (свободный ввод)",
			"Tasks": "Задания",
			"Teacher": "Преподаватель",
			"Teacher's comment": "Комментарий преподавателя",
			"Text": "Текст",
			"Text with formulas": "Текст с формулами",
			"Theme": "Тема",
			"Themes": "Темы",
			"Themes not found": "Темы не найдены",
			"Time": "Время",
			"Title": "Заголовок",
			"To make the input field inside of the text, please type the code like this": "Чтобы расположить поле ввода посреди текста, введите конструкцию следующего вида",
			"To what": "Чему",
			"Too many files selected. Maximum files count: {{count}}": "Выбрано слишком много файлов. Максимальное количество: {{count}}",
			"Trainers": "Тренажеры",
			"Users": "Пользователи",
			"What": "Что",
			"Wrong": "Неверно",
			"You won't be able to change your answer later": "Изменить ответ позже будет нельзя",
			"YouTube video": "Видео с YouTube",
			"Login": "Логин",
			"Password": "Пароль",
			"Log in": "Войти",
			"Reset password": "Новый пароль",
			"Last name": "Фамилия",
			"First name": "Имя",
			"Middle name": "Отчество",
			"Archived": "Архивировано",
			"Archive": "Архив",
			"Icons": "Иконки",
			"University": "Университет",
			"Institute": "Институт",
			"Faculty": "Факультет",
			"Chair": "Кафедра",
			"No": "Нет",
			"Type": "Тип",
			"Parent department": "Родительское подразделение",
			"Active": "Активно",
			"Edit department #{{id}}": "Изменить подразделение №{{id}}",
			"{{num}} of {{count}}": "{{num}} из {{count}}",
			"Task": "Задание",
			"Add task": "Добавить задание",
			"Manual check": "Проверка вручную",
			"Files as answers": "Файлы в качестве ответов",
			"Formulas": "Формулы",
			"Material": "Материал",
			"Question type": "Тип задания",
			"Answer type": "Тип ответа",
			"Points": "Баллы",
			"Images": "Изображения",
			"Audio": "Аудио",
			"Documents": "Документы",
			"Add material": "Добавить материал",
			"Drafts": "Черновики",
			"Exam": "Контрольная работа",
			"Trainer": "Тренажёр",
			"Show points for students": "Отображать баллы для студентов",
			"Poll": "Выбор из вариантов",
			"Choosing variants": "Выбор из вариантов",
			// "Sorting": "Сортировка",
			// "Accordance": "Соответсвие",
			"Free text": "Свободный ввод",
			// "Files": "Файлы",
			"Completion (table)": "Подстановка (таблица)",
			"Completion (free input)": "Подстановка (свободный ввод)",
			"Completion (select from list)": "Подстановка (выбор из списка)",
			"Completion (select from \"cloud\")": "Подстановка (выбор из \"облака\")",
			// "String": "Строка",
			"Multistring text": "Многострочный текст",
			"Rich text": "Многострочный текст с форматированием",
			"Float": "Произвольное число",
			"Any number": "Произвольное число",
			"Integer": "Целое число",
			// "Date": "Дата",
			// "Formula": "Формула",
			// "Text with formulas": "Текст с формулами",
			"Edit theme #{{id}}": "Изменить тему №{{id}}",
			"Edit task #{{id}}": "Изменить задание №{{id}}",
			"Background style": "Стиль фона",
			"Border style": "Стиль рамки",
			"Shuffle answer options": "Перемешивать варианты ответов",
			"Edit group #{{id}}": "Изменить группу №{{id}}",
			"Enter AsciiMath code": "Введите формулу на языке AsciiMath",
			"syntax help": "справка по синтаксису",
			"Waiting for check": "Ожидает проверки",
			"Wrong. Try again": "Неверно. Проверить снова",
			"Answer saved": "Ответ сохранён",
			"Poll mode": "Режим опроса",
			"Any right option": "Любой из верных вариантов",
			"All right options": "Все верные варианты",
			"Check all right options": "Выберите все верные варианты",
			"Materials not found": "Материалы не найдены",
			"Position": "Позиция",
			"Hide for students": "Скрыть от студентов",
			"Show for students": "Открыть для студентов",
			"Hidden images": "Скрытые изображения",
			"Edit student #{{id}}": "Изменить студента №{{id}}",
			"Move to": "Переместить в",
			"Access": "Доступ",
			"Add access": "Добавить доступ",
			"Select grade": "Выберите оценку",
			"Tasks to check": "Задания на проверку",
			"Excellent": "Отлично",
			"Rreload": "Обновить",
			"Try again": "Попробовать снова",
			"One task on page": "Отображать задания по одному",
			"Print PDF": "Печать PDF",
			"Set position": "Позиция",
			"Cards": "Карточки",
			"Card": "Карточка",
			"Questions page size": "Количество заданий на странице",
			"Restricted course access": "Ограниченный доступ к курсам",
			"Selected only": "Только выбранные",
			"Empty group": "Пустая группа",
			"Open on a separate page": "Открыть на отдельной странице",
			"New access": "Новый доступ",
			"Edit access": "Изменить доступ",
			"Start date": "Дата начала",
			"Start time": "Время начала",
			"End date": "Дата окончания",
			"End time": "Время окончания",
			"Group not selected": "Группа не выбрана",
			// "Shure?": "Уверены?",
			"Set as homework": "Задать на дом",
			"Teachers": "Преподаватели",
			"Copy to": "Копировать в",
			"Show translation": "Показать перевод",
			"Edit card": "Изм. карточку",
			"Hide card": "Скрыть карточку",
			"Hide translation": "Скрыть перевод",
			"Rotate": "Повернуть",
			"Download": "Скачать",
			"Not found": "Не найдено",
			"In the control work mode, the student does not see the result of checking tasks and cannot give an answer more than once": "В режиме контрольной работы студент не видит результат проверки заданий и не может давать ответ более одного раза",
			"Show inactive": "Показать неактивные",
			"Select group": "Выберите группу",
			"None": "Не выбрано",
			"Prev. page": "Пред. стр.",
			"Next page": "След. стр.",
			"Page": "Стр.",
			"Choose files": "Выбрать файлы",
			"Only one JPEG file accepted": "Можно выбрать один файл формата JPEG",
			"Autofill percents": "Заполнить проценты",
			"Use templates": "Использовать шаблоны",
			"Template": "Шаблон",
			"Description of file contents (not used for auto-check)": "Описание того, что должно быть в файле (не использется для автоматичской проверки)",
			"Answer": "Ответ",
			"Answers": "Ответы",
			"Answer content": "Содержание ответа",
			"Grade": "Оценка",
			"Your comment": "Ваш комментарий",
			"Save as template": "Сохранить как шаблон",
			"Video": "Видео",
			"Video link": "Ссылка на видео",
			"Video links": "Ссылки на видео",
			"Add video link": "Добавить ссылку на видео",
			"No answer": "Нет ответа",
			"Send": "Отправить",
			"Allow comments": "Разрешить комментарии",
			"No questions yet": "Задания отсутствуют",
			"New messages": "Новые сообщения",
			"Answered questions": "Ответы на комментарии к заданиям",
			"Edit task / card / group #{{id}}": "Редактировать задание / карточку / группу #{{id}}",
			"Free input": "Свободный ввод",
			"File with handwritten text + automatic recognition (OCR)": "Файл с рукописным текстом + автматическое распознавание (OCR)",
			"No messages yet": "Сообщения пока отсутствуют",
			"No notifications yet": "Уведомления пока отсутствуют",
			"No tasks yet": "Задания отсутствуют",
			"Restricted access": "Ограниченный доступ",
			"from": "с",
			"to": "до",
			"Variant": "Вариант",
			"Out of variants": "Вне вариантов",
			"Delete": "Удалить",
			"Failed to fetch": "Не удалось соединиться с сервером",
			"Show selected only": "Показать только выбранное",
			"Please select deparments": "Пожалуйста, выберите подразделения",
			"Department ID": "ID подразделения",
			"Group ID": "ID группы",
			"Name": "Название",
			"All departments": "Все подразделения",
			"Edit course": "Изменить курс",
			"Edit profile": "Изменить профиль",
			"Search in task text": "Поиск по тексту задания",
			"Course not found": "Курс не найден",
			"Loading": "Загрузка",
			"Task types": "Типы заданий",
			"Search by title": "Поиск по названию",
			"Search courses by title": "Поиск курсов по названию",
			"Filter by": "Включить в фильтр",
			"Shure?": "Уверены?",
			"New password sent to user's e-mail": "Новый пароль отправлен пользователю на e-mail",
			"Not restricted": "Не ограничено",
			"Limited access": "Ограниченный доступ",
			"Tests": "Контрольные работы",
			"New": "Новое",
			"Dynamic": "Динамическое",
			"Individual test": "Индивидуальная проверочная работа",
			"Tasks count": "Количество заданий",
			"Included tasks count": "Сколько заданий включить в работу",
			"Themes with tasks not found": "Темы с заданиями не найдены",
			"Tests not found": "Контролные работы не найдены",
			"Content": "Содержание",
			"templates": "шаблоны",
			"No templates yet": "Шаблоны пока отсутствуют",
			"Your answer": "Ваш ответ",
			"Add task / card / group": "Добавить задание / карточку / группу",
			"Answers templates": "Шаблоны ответов",
			"Disable unwanted using of answer templates": "Отключить нежелательное использование шаблонов",
			"Dsbl unwntd usg of answr tpls": "Откл. неж. исп. шаблонов",
			"Questions touched": "Заданий затронуто",
			"of": "из",
			"the expected content of the input field": "ожидаемое содержание поля ввода",
			"Example of input letters": "Пример для ввода букв",
			"Example of input words": "Пример для ввода слов",
			"Students not found": "Студенты не найдены",
			"Please select groups": "Пожалуйста, выберите группы",
			"Please select departments": "Пожалуйста, выберите подразделения",
			"All questions on one page": "Все задания на одной странице",
			"Total": "Всего",
			"Max points": "Макс. балл",
			"Student points": "Балл студента",
			"Exam is finished": "Работа завершена",
			"Question has formulas in text": "Формулы в тексте задания",
			"Copy ID to clipboard": "Скопировать ID в буфер обмена",
			"Search by name": "Поиск по названию",
			"": "",
		},
	},
	
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		
		resources,
		
		// lng: 'ru-RU',
		// lng: navigator.language,
		// lng: "en",
		fallbackLng: "en",
		
		keySeparator: false, // we do not use keys in form messages.welcome
		
		interpolation: {
			escapeValue: false, // react already safes from xss
		}
		
	});

export default i18n;