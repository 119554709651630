import VideoSource from './VideoSource'
import Logger from './Logger'

class VideoLinkHelper {
	
	static sources = {
		
		'youtube': new VideoSource({
		
			name: 'YouTube',
			countryName: 'USA',
		
			baseUrls: ['youtu.be', 'youtube.com', 'www.youtube.com'],
		
			extractVideoId: (url) => {
				
				let urlData = url.split('?')
		
				// if (YouTubeHelper.isShortUrl(url) || YouTubeHelper.isEmbedUrl(url)) {
				// 	urlData = url.split('/')
				// 	return urlData.pop()
				// }
				
				let videoId = null
				
				let urlQuery = urlData[1]
				
				if (urlQuery) {
					let pairs = urlQuery.split('&')
					pairs.forEach((pair) => {
						let pairData = pair.split('=')
						let key = pairData[0]
						if (key === 'v') {
							videoId = pairData[1]
						}
					})
				}
				
				return videoId
				
			},
			
			makeWidget: (videoSource, url, width, height) => {
				
				const videoId = videoSource.extractVideoId(url)
		
				return (
					<iframe
						className={'embed-responsive-item'}
						src={'https://www.youtube.com/embed/' + videoId}
						width={width}
						height={height}
						frameBorder={0}
						allowFullScreen
						// allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					></iframe>
				);
				
			}
			
		}),
		
		'vk': new VideoSource({
			
			name: 'VK',
			countryName: 'Russia',
			
			baseUrls: ['vk.com'],
			
			sampleVideoUrl: 'https://vk.com/video-190320587_456240821',
			sampleVideoId: '',
			
			makeWidget: (videoSource, url, width=853, height=480) => {
				
				let targetUrlPart = url.split('/').pop()
				targetUrlPart = targetUrlPart.split('-').pop()
				
				let targetUrlPartData = targetUrlPart.split('_')
				
				let oid = targetUrlPartData[0]
				let id = targetUrlPartData[1]
				
				//<iframe src="https://vk.com/video_ext.php?oid=-190320587&id=456240821&hd=2" width="853" height="480" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>
				
				return (
					<iframe 
						src={`https://vk.com/video_ext.php?oid=-${oid}&id=${id}&hd=2`}
						width={width}
						height={height} 
						allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" 
						frameborder="0" 
						allowfullscreen
					></iframe>
				)
				
			}
			
		}),
		
		'rutube': new VideoSource({
			
			name: 'Rutube',
			countryName: 'Russia',
			
			baseUrls: ['rutube.ru'],
			
			sampleVideoUrl: 'https://rutube.ru/video/acd6fd12f16928b5656bfaea708a5dff/?r=a',
			sampleVideoId: 'acd6fd12f16928b5656bfaea708a5dff',
			
			extractVideoId: (url) => {
				let urlData = url.split('/')
				return urlData[urlData.length - 2]
			},
			
			makeWidget: (videoSource, url, width = 720, height = 405) => {
				
				let widget = null
				
				let videoId = videoSource.extractVideoId(url)
				
				if (videoId) {
					
					widget = (
						<iframe 
							width={width}
							height={height} 
							src={`https://rutube.ru/play/embed/${videoId}`}
							frameBorder="0" 
							allow="clipboard-write; autoplay" 
							webkitAllowFullScreen 
							mozallowfullscreen 
							allowFullScreen
						></iframe>
					)
					
					// '<iframe width="720" height="405" src="https://rutube.ru/play/embed/acd6fd12f16928b5656bfaea708a5dff" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>'
					
				}
				
				return widget
				
			},
			
		}),
		
		'youku': new VideoSource({
			
			name: 'Youku',
			countryName: 'China',
			
			baseUrls: ['v.youku.com'],
			
			sampleVideoUrl: 'https://v.youku.com/v_show/id_XNTg4Nzk2NDY0MA==.html',
			sampleVideoId: 'XNTg4Nzk2NDY0MA',
			
			extractVideoId: (url) => {
				
				let prefix = 'https://v.youku.com/v_show/id_'
				
				let lastUrlPart = url.substring(prefix.length)
				
				let lastUrlPartData = lastUrlPart.split('==')
				
				let videoId = lastUrlPartData[0]
				
				return videoId
				
			},
			
			makeWidget: (videoSource, url, width, height) => {
				
				let videoId = videoSource.extractVideoId(url)
				
				return videoId ? (
					<iframe 
						width={width}
						height={height}
						src={'https://player.youku.com/embed/' + videoId} 
						frameborder={0} 
						allowfullscreen
					></iframe>
				) : null
				
			}
			
		}),
		
		'aparat': new VideoSource({
			
			name: 'Aparat',
			countryName: 'Iran',
			
			sampleVideoUrl: 'https://www.aparat.com/v/SmNrQ',
			sampleVideoId: 'SmNrQ',
			
			baseUrls: ['www.aparat.com', 'aparat.com'],
			
			extractVideoId: (url) => {
				return url.split('/').pop()
			},
			
			makeWidget: (videoSource, url, width, height) => {
				
				let widget = null
				
				let videoId = videoSource.extractVideoId(url)
				
				if (videoId) {
					
					widget = (
						<iframe
							width={width}
							height={height}
							src={`https://www.aparat.com/video/video/embed/videohash/${videoId}/vt/frame`}
						></iframe>
					)
					
				}
				
				return widget
				
			},
			
		}),
		
	}
	
	static getSource = (url) => {
		
		const logName = 'VideoLinkHelper'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		Logger.log(url, 'url', logAllow)

		if (!url) {
			return null
		}
		
		let out = null
		
		url = new URL(url)
		
		let hostname = url.hostname
		Logger.log(hostname, 'hostname', logAllow)
		
		let sources = this.sources
		
		let keys = Object.keys(sources)
		
		for (let key of keys) {
			
			let source = sources[key]
			
			let isFound = source.baseUrls.includes(hostname)
			
			if (isFound) {
				out = source
				break
			}
			
		}
		
		Logger.groupEnd(logAllow)
		
		return out
		
	}
	
	static makeWidget = (url, width, height) => {
		
		const logName = 'VideoLinkHelper.makeWidget'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		Logger.log(url, 'url', logAllow)
		
		let widget = null
		
		let source = this.getSource(url)
		Logger.log(source, 'source', logAllow)
		
		if (source) {
			
			let widgetMaker = source.makeWidget
			
			if (widgetMaker) {
				widget = widgetMaker(source, url, width, height)
			}
			
		}
		
		Logger.log(widget, 'widget', logAllow)
		
		Logger.groupEnd(logAllow)
		
		return widget
		
	}
	
	static urlToName = (url, addCountryName = 1) => {
		
		if (!url) {
			return 'url not provided'
		}
		
		let out = url
		
		let source = this.getSource(url)
		
		if (source) {
			
			let outData = [
				source.name
			]
			
			if (addCountryName) {
				outData.push(`(${source.countryName})`)
			}
			
			out = outData.join(' ')
			
		}
		
		return out
		
	}
	
}

export default VideoLinkHelper