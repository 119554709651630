import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from "react-bootstrap/Dropdown";
import i18next from "i18next";
import User from "../../models/User";
import {Link} from "react-router-dom";
import Utils from "../../helpers/Utils";
import Config from "../../helpers/Config";

export default class UserMenuBox extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		const user = this.props.user;
		
		return (
			<div
				className="UserMenuBox user-menu-box"
				title={'#' + user.id + ' | ' + user.email}
			>
				
				<Dropdown>
					
					<Dropdown.Toggle
						variant={'light'}
						id={'dropdown-basic'}
						className={'btn-unstyled'}
					>
						<div className="row">
							<div className="col-auto text-right">
								<span className="user-name">
									{user.getDisplayName(1)}
								</span>
								<br/>
								{/*<small>{user.role_name}{user.group_name ? <span>&nbsp;&bull;&nbsp;{user.group_name}</span> : ''}</small>*/}
								<small className={'role-name'}>{i18next.t(user.role_name)}</small>
								{user.groups.map(group => {
									return (
										<span key={'group-' + group.id}>
											&nbsp;&bull;&nbsp;
											<small
												className={'group-name'}
												title={'#' + group.id}
											>{group.name}</small>
										</span>
									);
								})}
								{(Utils.isLoc()) &&
									<>&nbsp;&bull;&nbsp;<small>#{user.id}</small></>
								}
							</div>
							<div className="col-auto text-center pl-0">
								<span className="avatar-box"></span>
							</div>
						</div>
					</Dropdown.Toggle>
					
					<Dropdown.Menu>
						
						{(this.props.skins.length > 0) &&
							
							<>
								
								<div className="dropdown-header pt-3">
									{i18next.t("Skins")}
								</div>
								
								{this.props.skins.map(skin => {
									let alias = skin.alias;
									return (
										<Dropdown.Item
											key={'skin' + alias}
											onClick={() => {
												this.props.setSkin(alias);
											}}
										>{skin.name}</Dropdown.Item>
									);
								})}
						
								<Dropdown.Divider/>
							
							</>
							
						}
						
						{user.can('editSelfProfile') &&
							<Dropdown.Item
								onClick={this.props.edit}
							>{i18next.t("Edit profile")}</Dropdown.Item>
						}

						<Dropdown.Item
							
							onClick={this.props.logout}
						>
							<b className={`text-danger`}>{i18next.t("Logout")}</b>
						</Dropdown.Item>
						
						{(user.can('dev') || Utils.isLoc()) &&
							
							<>
							
								<Dropdown.Divider/>
							
								<div className="dropdown-header pt-3">
									{i18next.t("Dev tools")}
								</div>
								
								{/* 
								<Dropdown.Item>
									<Link to={'/question-manager'}>{i18next.t("QuestionManager")}</Link>
								</Dropdown.Item>
								*/}
								
								<Link
									to={'/question-manager'}
									className={`dropdown-item`}
								>{i18next.t("QuestionManager")}</Link>
								
								{(this.props.toggleBreakpointIndicator) &&
									<Dropdown.Item
										onClick={this.props.toggleBreakpointIndicator}
									>{i18next.t("Toogle breakpoint indicator")}</Dropdown.Item>
								}
								
							</>
							
						}
						
						<Dropdown.Divider/>
						
						<Dropdown.Item>
							<small className={`text-muted`}>{Config.buildDatetime}</small>
						</Dropdown.Item>
					
					</Dropdown.Menu>
				
				</Dropdown>
			
			</div>
		);
	}
	
}

UserMenuBox.propTypes = {
	user: PropTypes.instanceOf(User),
	logout: PropTypes.func,
	skins: PropTypes.array,
	setSkin: PropTypes.func,
	toggleBreakpointIndicator: PropTypes.func,
};

UserMenuBox.defaultProps = {
	skins: [],
};