import React from 'react';
import PropTypes from 'prop-types';
import LoginForm from "./LoginForm";
import Config from "../../helpers/Config";
import i18next from "i18next";
import LangMenu from "./LangMenu";

import "./LoginPage.css";

import kfuLogoRu from '../../img/kfu-logo-big-ru.svg';
import kfuLogoEn from '../../img/kfu-logo-big-en.svg';
import studerusTextLogo from '../../img/studerus-text-logo.svg';

export default class LoginPage extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		let currentLangData = this.props.langs[i18next.language];
		
		return (
			
			<div
				className={[
					'LoginPage',
					// 'h-100',
					// 'align-self-center',
				].join(' ')}
			>
				
				{/*<div className="mobile-logo d-md-none">
                    <img src={} alt=""/>
                </div>*/}
				
				<div
					className={[
						'row',
						'h-100',
						'align-items-center',
					].join(' ')}
				>
					
					<div
						className={[
							
							'logo-col',
							
							'col-xl-4',
							'offset-xl-1',
							
							'col-sm-4',
							'offset-sm-1',
							
							'align-self-center',
							'text-center',
							
							// 'd-none',
							// 'd-md-block',
						
						].join(' ')}
					>
						
						<a className={'logo-link kfu-logo-link'} href={Config.kfuUrl} target={'_blank'}>
							<img className={'logo kfu-logo'}
								 src={i18next.language === 'ru' ? kfuLogoRu : kfuLogoEn}
								 alt="kfu-logo"
							/>
						</a>
						
						<img src={studerusTextLogo} alt="studerus_text_logo" className="studerus-logo"/>
					
					</div>
					
					<div
						className={[
							
							'form-col',
							
							'col-xl-4',
							'offset-xl-2',
							
							'col-sm-5',
							'offset-sm-1',
							
							'align-self-center',
						
						].join(' ')}
					>
						
						<div className="list login-form-box">
							<LoginForm submit={this.props.login}/>
						</div>
						
						<div className="lang-menu-box">
							
							<LangMenu
								langs={this.props.langs}
								changeLang={this.props.changeLang}
								currentLangAlias={this.props.currentLangAlias}
							/>
						
						</div>
					
					</div>
				
				</div>
			
			</div>
		);
	}
	
}

LoginPage.propTypes = {
	login: PropTypes.func.isRequired,
	langs: PropTypes.object.isRequired,
	changeLang: PropTypes.func.isRequired,
	currentLangAlias: PropTypes.string,
};

LoginPage.defaultProps = {};